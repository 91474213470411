@import '../../styles/colors';

$bg-color: #5b9bd4;

.checkboxTick {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  margin: 5px 0px 5px 10px;
  width: 16px;
  height: 16px;
  outline: none;
  border: none;

  &:before {
    content: "\2713"; /* Unicode character for the tick symbol */
    position: relative;
    top: -8px;
    left: 13px;
    font-size: 16px;
    font-weight: 700;
    color: $bg-color;
    visibility: hidden;
  }
  &:checked::before {
    visibility: visible;
  }
}

.infoIcon {
  cursor: pointer;
  margin: 0 10px;
}

.expandableCell {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  height: 100%;
  // min-width: 100%;
  align-items: center;
  align-content: center;

  &:hover {
    background-color: #eef3f8;
    overflow: visible;
    text-overflow: unset;
    position: absolute;
    z-index: 10;
    // box-shadow: 2px 0px 5px -2px $divider-color;
    padding-right: 10px;
  }
}

.lastRunStatus {
  padding: 0px 0px 5px;
}
