@import '../../styles/colors';
@import '../../styles/form';

.apiKeyWidget {
  display: flex;
  flex-direction: row;
  gap: 5px;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  // font-size: medium;
  border: 1px solid $divider-color;
  border-radius: 5px;
  padding: 5px;
}

.apiKeyContainer {
  padding: 10px;
  flex: 1;
  color: $text-color;
}

.hidden {
  user-select: none;
  pointer-events: none;
}

.showHideButton {
  background: none;
  color: $text-color-disabled;
  border: none;

  &:hover {
    cursor: pointer;
  }
}

.copyButton {
  border: none;
  background: none;
  color: $text-color-disabled;
  font-size: medium;
  // background-color: rgba($divider-color, 0.6);
  padding: 8px;
  border-radius: 3px;
  transition: color, background-color 150ms ease-in-out;
  position: relative;
  &:hover {
    cursor: pointer;
    color: $text-color-light;
    background-color: rgba($divider-color, 0.6);
  }
}

.copyButtonContainer {
  position: relative;
}

.toolTipText {
  position: absolute;
  z-index: 200;
  top: -2.5rem;
  left: -0.75rem;
  background-color: $text-color;
  padding: 5px;
  color: $background-color;
  white-space: nowrap;

  &::after {
    content: "";
    position: absolute;
    top: 100%; // Places it right below the tooltip box
    left: 50%; // Centers it horizontally
    transform: translateX(-50%); // Perfect centering
    border-width: 5px;
    border-style: solid;
    border-color: $text-color transparent transparent transparent; 
  }
}

.regenerateButton {
  display: flex;
  flex-direction: row;
  gap: 5px;
  font-size: small;
  background: none;
  border: none;
  padding: 3px;
  color: $text-color-disabled;
  transition: color 100ms;
  margin-left: auto;
  &:hover {
    cursor: pointer;
    color: $primary-color;
  }
}

.content {
  display: flex;
  flex-direction: column;
  gap: 10px;
  @include form-fields;
}

.buttonsContainer {
  display: flex;
  flex-direction: row;
  gap: 30px;
  margin-left: auto;
  button {
    color: $primary-color;
    text-transform: uppercase;
    background: none;
    border: none;
    &:hover {
      cursor: pointer;
    }
  }
}

.menuContainer {
  position: relative;
  font-family: Arial, sans-serif;
}

.menuButton {
  background: none;
  border: none;
  cursor: pointer;
  padding: 8px;
  border-radius: 4px;

  &:focus {
    outline: none;
  }
}

.menuItems {
  position: absolute;
  min-width: 120px;
  background-color: #ffffff;
  border-radius: 4px;
  padding: 0;
  top: 100%;
  z-index: 100;
  box-shadow: 0 2px 5px rgba(0,0,0,0.15);

  .menuItem {
    display: block;
    width: 100%;
    padding: 8px 16px;
    border: none;
    background: none;
    text-align: left;
    border-bottom: 1px solid #ddd;
    cursor: pointer;
    font-size: 12px;
    color: $primary-color;

    &:hover,
    &.active {
      background-color: #f7f7f7;
    }

    &:focus {
      outline: none;
    }

    &:last-child {
      border-bottom: none;
    }
  }

  a:hover {
    text-decoration: underline;
  }
}

.dropdownIcon {
  margin-left: 5px;
}

