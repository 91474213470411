@import '../../styles/colors';

.tooltipTrigger {
  position: relative;

  .tooltip {
    position: absolute;
    display: inline;
    z-index: 1000;
    background-color: $text-color;
    padding: 10px;
    border-radius: 5px;
    color: $background-color;
    white-space: nowrap;
    opacity: 0;
    visibility: hidden;
    font-weight: normal;
    font-size: small;
  }

  .top {
    bottom: 130%;
    left: 50%;
    transform: translateX(-50%);
    
    &::after {
      content: "";
      position: absolute;
      top: 100%;
      left: 50%;
      transform: translateX(-50%);
      border-width: 5px;
      border-style: solid;
      border-color: $text-color transparent transparent transparent; 
    }
  }

  .bottom {
    top: 130%;
    left: 50%;
    transform: translateX(-50%);

    &::after {
      content: "";
      position: absolute;
      bottom: 100%;
      left: 50%;
      transform: translateX(-50%);
      border-width: 5px;
      border-style: solid;
      border-color: transparent transparent $text-color transparent; 
    }
  }

  .left {
    right: 130%;
    top: 10px;
    transform: translateY(-50%);

    &::after {
      content: " ";
      position: absolute;
      top: 50%;
      left: 100%;
      margin-top: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: transparent transparent transparent $text-color;
    }
  }

  .right {
    left: 150%;
    top: 10px;
    transform: translateY(-50%);

    &::after {
      content: " ";
      position: absolute;
      top: 50%;
      right: 100%;
      margin-top: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: transparent $text-color transparent transparent;
    }
  }
  
}

.tooltipTrigger:hover {
  .tooltip {
    opacity: 1;
    visibility: visible;
  }
}

