@import '../../styles/form';
$bg-color: #5b9bd4;
$disabled-color: #ccc;
$button-hover-bg-color: #666;

.formButton {
  border: none;
  background-color: transparent;
  color: $bg-color;
  border-radius: 0px;
  cursor: pointer;
  white-space: nowrap;
}

.deleteInput {
  @include form-fields;
}

.buttonContainer {
  padding-top: 10px;
  display: flex;
  justify-content: space-between;
  gap: 130px;
}

.buttonRightContainer {
  padding-top: 10px;
  display: flex;
  margin-left: auto;
  gap: 30px;
  justify-content: end;
}
