@import '../../styles/colors';

.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal {
  background: white;
  padding: 20px 25px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  position: relative;
  width: 90%;
  // max-width: 450px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
}

.heading {
  display: flex;
  flex-direction: row;
}

.modalTitle {
  color: #5b9bd4;
  padding-bottom: 10px;
}

.content {
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 10px;
  font-size: 0.9rem;
  color: $text-color;
}

.closeButton {
  color: $divider-color;
  margin-left: auto;
  font-size: 0.7rem;
  &:hover {
    cursor: pointer;
  }
}

.disabled {
  pointer-events: none;
}
