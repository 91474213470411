@import '../../styles/layout';
@import '../../styles/colors';
@import '../../styles/form';

$header-padding: 20px 20px 10px;
$button-bg-color: #5b9bd4;

.container {
  display: flex;
  flex-direction: row;
  height: 100%;
  gap: 10vh;
  padding: 10px;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 20px;
  }
}

.content {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.tableContainer {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  height: 100%;
}

.uploadContainer {
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 10px;
  padding: 10px 40px;
}

.uploadButton {
  justify-content: center;
  align-content: center;
}

.dragAndDrop {
  background-color: rgba($divider-color, 0.2);
  border: 3px dotted $text-color-light;
  justify-items: center;
  align-items: center;
  text-align: center;
  padding: 10vh 0;
  border-radius: 8px;

  .chooseFile {
    text-decoration: underline;
    &:hover {
      cursor: pointer;
    }
  }
}

.upload {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 0px 5vw 0px 0px;
}

.stepIndicator {
  display: flex;
  flex-direction: row;
  gap: 2px;
  display: flex;
  gap: 5px;
}

.step {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 0.9rem;
  gap: 5px;
  min-width: 10em;
  border-top: 6px solid $divider-color;
  padding-top: 5px;
}

.stepLabel {
  color: $text-color-disabled;
  transition: color 200ms;
}

.stepCircle {
  border: 1px solid $divider-color;
  background-color: $background-color;
  width: 2em;
  height: 2em;
  text-align: center;
  text-justify: center;
  align-content: center;
  border-radius: 50%;
  color: $divider-color;
  font-size: 0.5rem;
  font-weight: bold;
  justify-content: center;
  align-items: center;
}

.completedStep {
  border-color: #8fbbe2;
  color: $text-color-light;
  .stepCircle {
    background-color: $background-color;
    color: #8fbbe2;
    border-color: #8fbbe2;
  }

  .stepLabel {
    color: $text-color;
  }
}

.currentStep {
  border-color: $primary-color;
  .stepCircle {
    background-color: $primary-color;
    color: $background-color;
    border: none;
  }

  .stepLabel {
    color: $text-color;
  }
}

.buttonsContainer {
  display: flex;
  width: 100%;
  justify-content: space-between;

  .secondaryButton {
    background-color: $background-color;
    color: $text-color-light;
  }

  .primaryButton {
    background-color: $secondary-color;
    color: $background-color;
  }

  button {
    padding: 10px 20px;
    border: 1px solid $divider-color;
    border-radius: 10px;
    &:hover {
      cursor: pointer;
    }
    &:disabled {
      filter: brightness(0.9);
      pointer-events: none;
    }
  }
}

.file {
  padding: 15px;
  border: 1px solid $divider-color;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  color: $text-color;
  
  .fileName {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    flex: 1 1 auto;
    min-width: 0;
  }

  .right {
    margin-left: auto;
    display: flex;
    flex-direction: row;
    gap: 15px;
    align-items: center;
  }

  .fileSize {
    color: $text-color-light;
    white-space: nowrap;
  }
  
  .removeFileButton {
    margin-left: auto;
    background-color: $background-color;
    color: $divider-color;
    border: none;
    &:hover {
      cursor: pointer;
    }
  }
}

.files {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.disabled {
  pointer-events: none;
}

.columnMappings {
  display: flex;
  flex-direction: column;
  gap: 7px;
  height: 60vh;
  overflow: auto;
  @include form-fields;
}

.columnMappingsHeader {
  display: grid;
  grid-template-columns: minmax(10em, 0.8fr) minmax(15em, 1.2fr) minmax(15em, 1fr);
  column-gap: 15px;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid $divider-color;
  font-weight: 500;
  z-index: 2;
  position: sticky;
  top: -1px;
  background-color: $background-color;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 5px;
}

.mapping {
  display: grid;
  // flex-direction: row;
  grid-template-columns: minmax(10em, 0.8fr) minmax(15em, 1.2fr) minmax(15em, 1fr);
  column-gap: 15px;
  justify-content: space-between;
  align-items: center;
  font-size: 0.875rem;
  padding-left: 5px;
  // gap: 10px;
  
  &:hover {
    background-color: rgba($divider-color, 0.2);
  }
  select {
    // width: 20em;
    flex-grow: 0;
    font-size: inherit;
    background-color: rgba($background-color, 0.5);
    padding: 8px;
    option {
      color: $text-color;
    }
  }
  .selectedNone {
    color: $text-color-disabled
  }
  .error {
    color: rgba($error, 0.6);
    border-color: $error;
  }

  .columnName {
    font-weight: 400;
    // width: 12em;

    .optional {
      color: $text-color-disabled;
      font-size: 0.7rem;
    }
  }

  .dataPreview {
    width: 15em;
    max-width: 15em;
    flex-grow: 0;
    text-overflow: ellipsis;
    overflow: hidden;
    text-align: left;
    white-space: nowrap;
  }
}

.row {
  display: flex;
  flex-direction: row;
  align-items: flex-end;

  p {
    word-wrap: normal;
  }
}

.downloadButton {
  background-color: $background-color;
  border: 1px solid $text-color;
  color: $text-color-light;
  padding: 8px 15px;
  border-radius: 20px;
  margin-left: auto;
  font-size: small;
  
  &:hover {
    cursor: pointer;
    color: $text-color;
  }
}

.popUpButtonsContainer {
  display: flex;
  flex-direction: row;
  gap: 30px;
  padding-top: 10px;

  button {
    background-color: #00000000;
    color: $primary-color;
    border: none;
    text-transform: uppercase;
    &:hover {
      cursor: pointer;
    }

    &:disabled {
      color: $text-color-disabled;
      pointer-events: none;
    }
  }
  
  .nextButton {
    margin-left: auto;
  }
}

.blueText {
  color: $primary-color;
}

.ppTable {
    flex: 1;
    display: flex;
    justify-content: center; /* Centers the content horizontally */
    align-items: center; /* Centers the content vertically */
    text-align: center;
    flex-direction: column;

    nav {
        justify-content: center;
    }
}

.backButton {
    color: #5b9bd4;
    text-decoration: none;
    text-decoration: underline;
}

.backButton:hover {
    color: #567d9e;
    text-decoration: none;
    text-decoration: underline;
}


.tableHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    // margin-bottom: 10px;
    // padding: $header-padding;
}

.mainContent {
    @include page-content;
    display: flex;
    flex-direction: column;
}

.heading {
    color: $button-bg-color;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.uploadButton {
  padding: 10px 20px;
  background-color: $primary-color;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  font-weight: bold;
  display: flex;
  gap: 7px;

  &:hover {
      background-color: $accent-2;
  }

  &:focus {
      outline: none;
      // Consider adding a custom style for focus to maintain accessibility
  }

  &:active {
      transform: translateY(1px);
  }
}
