@import '../../styles/layout';
@import '../../styles/colors';

.tabLayout {
  display: flex;
  flex-direction: column;
  gap: 10px;
  flex: 1;
  overflow: auto;
  height: 100%;
}

.tabsContainer {
  display: inline-flex;
  flex-direction: row;
  gap: 30px;
  // margin-bottom: 10px;
  padding: 0px 25px;
  border-bottom: 1px solid $divider-color;
  box-shadow: 0 0 2px 1px rgba($divider-color, 0.2);
}

.tab {
  color: $text-color-light;
  font-weight: bold;
  padding: 10px 5px;
  // border-radius: 20px;
  font-size: small;
  transition: border 100ms;
}

.activeTab {
  color: $text-color;
  // background-color: $text-color;
  // border: 1px solid $divider-color;
  // box-shadow: 0px 0px 1px 0px $divider-color;
  border-bottom: 3px solid $primary-color;
  margin-bottom: -0.5px;
}

.tabContent {
  @include page-content;
  flex: 1;
  overflow: auto;
}
