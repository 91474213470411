@import './colors';

@mixin data-table($height: 70vh) {
  overflow: auto;
  height: $height;
  position: relative;

  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background-color: $divider-color;
    border-radius: 4px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background-color: darken($divider-color, 8%);
  }

  :global {
    .rdt_TableHead {
      color: $text-color;
      text-transform: uppercase;
      font-weight: 600;
      font-size: 0.75rem;
      border-bottom: 1px solid $divider-color;
      z-index: 1000;
      position: sticky;
      top: 0;
    }
  
    .rdt_TableRow {
      color: $text-color;
      border-color: $divider-color;
      font-size: 0.9rem;
      &:hover {
        background-color: #eef3f8;
      }
    }

    .rdt_Pagination {
      position: relative;
      bottom: 0;
      left: 0;
      min-height: 45px;
    }
  }
}
